import React, { useState } from "react";
import { RiSearch2Line } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import Logo from "../../Images/Logo.svg";
import UK from "../../Images/UK.png";

import SA from "../../Images/SA.png";
import { VscListFlat } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { addAllDBLab } from "../../GlobalData/SystemDashBoard/LabSDBSlice";
import { useDispatch } from "react-redux";
import { addAllTupe } from "../../GlobalData/SystemDashBoard/TupeSlice";
import { addAllDBAnalysis } from "../../GlobalData/SystemDashBoard/AnalysisSBDSlice";
import { addAllGender } from "../../GlobalData/SystemDashBoard/GenderSlice";
import { addAllTestMethod } from "../../GlobalData/SystemDashBoard/TestMethodSlice";
import { addAllUnit } from "../../GlobalData/SystemDashBoard/UnitSlice";
import axios from "axios";
import Swal from "sweetalert";
function drawer() {
  document.getElementById("drawerBody").classList.remove("hidden");
  document
    .getElementById("drawerBody")
    .classList.add(
      "flex",
      "fixed",
      "h-screen",
      "w-full",
      "bg-transparent",
      "top-0",
      "left-0",
      "lg:hidden",
      "z-10"
    );
}
function closeDrawer() {
  document.getElementById("drawerBody").classList.add("hidden");
}
function HeaderSystemDB({ section, DataBeforeFilter }) {
  const [searchInput, setSearchInput] = useState("");

  const dispatch = useDispatch();
  const [lang, SetLang] = useState("UK");

  const ShowLaguages = () => {
    if (document.getElementById("lang").classList.contains("hidden")) {
      document.getElementById("lang").classList.remove("hidden");
      document.getElementById("CurrentLang").classList.add("hidden");
      document.getElementById("CloseLangIcon").classList.remove("hidden");
    } else if (!document.getElementById("lang").classList.contains("hidden")) {
      document.getElementById("lang").classList.add("hidden");
      document.getElementById("CurrentLang").classList.remove("hidden");
      document.getElementById("CloseLangIcon").classList.add("hidden");
    }
  };

  const handleChangeSearch = (e) => {
    setSearchInput(e.target.value);
    if (section === "analytic") {
      if (!e.target.value) {
        dispatch(addAllDBAnalysis(DataBeforeFilter));
      }
      let DataFiltered = DataBeforeFilter.filter((item) => {
        return (
          (item.test_print_name &&
            item.test_print_name
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (item.name &&
            item.name.toLowerCase().includes(e.target.value.toLowerCase()))
        );
      });

      dispatch(addAllDBAnalysis(DataFiltered));
    }

    if (section === "TestUnit") {
      if (!e.target.value) {
        dispatch(addAllUnit(DataBeforeFilter));
      } else {
        let DataFiltered = DataBeforeFilter.filter((item) => {
          return item.unit.toLowerCase().includes(e.target.value.toLowerCase());
        });

        dispatch(addAllUnit(DataFiltered));
      }
    }

    if (section === "testMethod") {
      if (!e.target.value) {
        dispatch(addAllTestMethod(DataBeforeFilter));
      } else {
        let DataFiltered = DataBeforeFilter.filter((item) => {
          return item.test_method
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        });

        dispatch(addAllTestMethod(DataFiltered));
      }
    }

    if (section === "gender") {
      if (!e.target.value) {
        dispatch(addAllGender(DataBeforeFilter));
      } else {
        let DataFiltered = DataBeforeFilter.filter((item) => {
          return item.name.toLowerCase().includes(e.target.value.toLowerCase());
        });

        dispatch(addAllGender(DataFiltered));
      }
    }
    if (section === "Tupe") {
      if (!e.target.value) {
        dispatch(addAllTupe(DataBeforeFilter));
      } else {
        let DataFiltered = DataBeforeFilter.filter((item) => {
          return item.tupe.toLowerCase().includes(e.target.value.toLowerCase());
        });

        dispatch(addAllTupe(DataFiltered));
      }
    }

    if (section === "Labs") {
      if (!e.target.value) {
        dispatch(addAllDBLab(DataBeforeFilter));
      } else {
        let DataFiltered = DataBeforeFilter.data.filter((item) => {
          return (
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.lab_name.toLowerCase().includes(e.target.value.toLowerCase())
          );
        });

        let meta = {
          current_page: DataBeforeFilter.meta.current_page,
          from: DataBeforeFilter.meta.from,
          last_page: DataBeforeFilter.meta.last_page,
          links: DataBeforeFilter.meta.links,
          path: DataBeforeFilter.meta.path,
          per_page: DataBeforeFilter.meta.per_page,
          to: DataBeforeFilter.meta.to,
          total: DataBeforeFilter.meta.total,
        };
        let Data = {
          data: DataFiltered,
          links: DataBeforeFilter.links,
          meta: meta,
        };

        dispatch(addAllDBLab(Data));
      }
    }
  };
  const handleLogout = () => {
    axios
      .get("admin-scope/logout-admin")
      .then((res) => {
        localStorage.setItem("token", "");
        localStorage.setItem("admin", false);
        window.location.replace("/Login");
      })
      .catch((err) => {
        if (err.response)
          Swal({
            title: "Opps, Error",
            type: "error",
            icon: "error",
            text: `${err.response.data.message}`,
          });
      });
  };
  return (
    <div className="w-full   relative flex  justify-between">
      <img
        src={Logo}
        className="w-[5rem] h-20 rounded-xl bg-[#0D2135] ml-9 mr-8 pb-3 pt-2 hidden lg:block"
      />
      <div className="bg-white mr-[-1rem] lg:mr-0 rounded-l-xl ">
        <VscListFlat
          id="drawerbtn"
          className="text-black  text-xl m-2  cursor-pointer w-10 h-20 lg:hidden "
          onClick={() => drawer()}
        />
      </div>
      <div className=" p-5 bg-white flex items-center justify-between flex-grow rounded-xl">
        <div className="flex items-center w-[32%] flex-grow lg:flex-grow-0 mr-5 lg:mr-0  rounded-2xl bg-[#F2F4F7]">
          <RiSearch2Line className="ml-2 text-[#B9B9B9]  text-3xl" />
          <input
            value={searchInput}
            onChange={handleChangeSearch}
            placeholder="Find the names of the reviewers here"
            className="p-2 bg-[#F2F4F7] w-full  focus:outline-none px-4  placeholder-[#B9B9B9] text-left rounded-2xl"
            type="text"
          />
        </div>
      </div>
      {/* Drawer */}
      <div id="drawerBody" className=" hidden  ">
        <div
          id="drawer"
          className=" w-full bg-[#0D2135] opacity-80 h-full md:w-1/2"
        >
          <div className="p-4">
            <AiOutlineClose
              className="text-xl text-white cursor-pointer "
              onClick={() => closeDrawer()}
            />
          </div>
          <div className="flex flex-col ml-10 md:ml-20 space-y-1 w-full justify-center  h-4/5 text-white ">
            {/* border-b-2 border-[#847244] */}
            <Link
              to="/"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center  text-xl  p-2 md:w-1/2 w-3/4"
            >
              <div className="font-Poppins-Regular text-sm">Type of Tupe</div>
            </Link>
            <Link
              to="/Analytic"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2 "
            >
              <div className="font-Poppins-Regular text-sm">Analytic</div>
            </Link>
            <Link
              to="/Labs"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
            >
              <div className="font-Poppins-Regular text-sm">Labs </div>
            </Link>
            {/* <Link
              to="//Quantity"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
            >
              <div className="font-Poppins-Regular text-sm">Quantity </div>
            </Link> */}
            <Link
              to="/MeasuringUnit"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
            >
              <div className="font-Poppins-Regular text-sm">Test Unit </div>
            </Link>
            <Link
              to="/TestMethod"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
            >
              <div className="font-Poppins-Regular text-sm">Test Method </div>
            </Link>
            <Link
              to="/Gender"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
            >
              <div className="font-Poppins-Regular text-sm">Gender </div>
            </Link>
            <Link
              to="/Profile"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
            >
              <div className="font-Poppins-Regular text-sm">Profile </div>
            </Link>
            <Link
              to="/"
              className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
            >
              <div
                onClick={handleLogout}
                className="font-Poppins-Regular text-sm"
              >
                Logout
              </div>
            </Link>
          </div>
        </div>
        <div className="hidden sm:block h-full w-full bg-black opacity-40" />
      </div>
    </div>
  );
}

export default HeaderSystemDB;
