// import { t } from "i18next";
import React, { useEffect, useState } from "react";
// import Intrputik from "./Intrputik";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addAllDBAnalysis } from "../../../GlobalData/SystemDashBoard/AnalysisSBDSlice";
import swal from "sweetalert";
import Swal from "sweetalert";
import DeleteMainSection from "./DeleteMainSection";

function EditSection({ setDataBeforeFilter,show, setShow, id }) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [ready, setReady] = useState(true);
  const [OpenDelete, setOpenDelete] = useState(false);

  const dispatch = useDispatch();
  const getSectionDetails = async (id) => {
    setName("Loading...");
    await axios
      .get(`admin-scope/mainSection?section_id=${id}`)
      .then((response) => {
        setName(response.data.name);
      });
  };

  useEffect(() => {
    if (show) getSectionDetails(id);
    setError("");
  }, [id, show]);

  const getSections = async (page) => {
    await axios.get(`admin-scope/sections`).then((response) => {
      dispatch(addAllDBAnalysis(response.data));
      setDataBeforeFilter(response.data)
    });
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const Remove = () => {
    setOpenDelete(true);
  };
  const handleClick = async () => {
    setReady(false);
    await axios
      .put(`admin-scope/update-mainSection?section_id=${id}`, { name })
      .then((res) => {
        setReady(true);

        setShow(false);

        setError("");
        setName("");
        getSections();
      })
      .catch((err) => {
        if (err.response.status === 500) {
          Swal({
            title: "Opps, Error",
            type: "error",
            icon: "error",
            text: `The system is under maintenance, please try again later, thank you`,
          });
        } else {
          setReady(true);
          console.log("klfjsldkfjldkfjsdlkfjsdlj");
          console.log(err.response);
          setError(err.response.data.error[0]);
          // if (err)
          // swal("Oh noes!", `${err.response.data.message}`, "error");
        }
      });
  };
  return (
    <div className={`${show === true ? "block" : "hidden"}`}>
      <DeleteMainSection
        setDataBeforeFilter={setDataBeforeFilter}
        setShow={setShow}
        setOpen={setOpenDelete}
        open={OpenDelete}
        id={id}
        name={name}
      />
      <div className="w-full flex space-x-5 justify-end mb-5 mt-16 pr-1">
        <div className="flex items-center space-x-2">
          <input type="checkbox" />
          <label className="font-Poppins-Regular text-black text-xs">
            {"Single Print"}
          </label>
        </div>
      </div>
      <div
        className={` border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4    relative m-auto border-[1px] rounded-xl `}
      >
        <input
          name="SectionName"
          value={name}
          onChange={handleChangeName}
          placeholder={"Section name"}
          type="text"
          className="w-full bg-[#F9FAFF] font-Poppins-Regular text-sm text-[#707070] outline-0 ring-0"
        />
      </div>
      <span className="text-sm text-red-600">{error}</span>
      <div className={`flex justify-end space-x-8 mt-8 `}>
        <div
          onClick={Remove}
          className="bg-[#F04438] border-[1px] border-[#D0D5DD] w-fit  flex items-center justify-center px-16 py-2 rounded-xl cursor-pointer "
        >
          <p className="text-sm flex items-center justify-center text-white font-Poppins-Regular">
            {"Delete"}
          </p>
        </div>
        <button
          onClick={handleClick}
          disabled={!ready}
          className={` ${
            ready ? "bg-[#B7C835]" : "bg-gray-600"
          } w-fit  flex items-center justify-center px-28 py-3 rounded-xl  `}
        >
          <p className="text-sm flex items-center justify-center text-white font-Poppins-Regular">
            {"update"}
          </p>
        </button>
      </div>
    </div>
  );
}

export default EditSection;
