/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllDBLab,
  selectDBLabs,
} from "../../../GlobalData/SystemDashBoard/LabSDBSlice";
import axios from "axios";
import Swal from "sweetalert";

export default function EditLabSystemDB({
  setDataBeforeFilter,
  open,
  setOpen,
  id,
}) {
  const cancelButtonRef = useRef(null);
  const LabSelector = useSelector(selectDBLabs);
  const [selected, Setselected] = useState({});
  const [Image, SetImage] = useState();
  const [formData, setFormData] = useState({
    lab_name: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    oldPassword: "",
    newPassword: "",
    ConfnewPassword: "",
  });

  const [errorName, setErrorName] = useState("");
  const [errorNameLab, setErrorNameLab] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    // Setselected(
    //   LabSelector.data
    //     ? LabSelector.data[
    //         LabSelector.data.findIndex((tupeItem) => tupeItem.id === id)
    //       ]
    //     : ""
    // );

    SetImage(
      `https://aurora-team.com/lab/${
        LabSelector.data
          ? LabSelector.data[
              LabSelector.data.findIndex((tupeItem) => tupeItem.id === id)
            ]?.src
          : ""
      }`
    );
    setFormData({
      lab_name: LabSelector.data
        ? LabSelector.data[
            LabSelector.data.findIndex((tupeItem) => tupeItem.id === id)
          ]?.lab_name
        : "",
      name: LabSelector.data
        ? LabSelector.data[
            LabSelector.data.findIndex((tupeItem) => tupeItem.id === id)
          ]?.name
        : "",
      email: LabSelector.data
        ? LabSelector.data[
            LabSelector.data.findIndex((tupeItem) => tupeItem.id === id)
          ]?.email
        : "",
      phone: LabSelector.data
        ? LabSelector.data[
            LabSelector.data.findIndex((tupeItem) => tupeItem.id === id)
          ]?.phone
        : "",
      address: LabSelector.data
        ? LabSelector.data[
            LabSelector.data.findIndex((tupeItem) => tupeItem.id === id)
          ]?.address
        : "",
      oldPassword: "",
      newPassword: "",
      ConfnewPassword: "",
    });
  }, [open]);

  const getLaps = async (page) => {
    await axios.get(`admin-scope/accounts?page=${page}`).then((response) => {
      dispatch(addAllDBLab(response.data));
      setDataBeforeFilter(response.data);
    });
  };

  const Edit = async () => {
    if (
      !formData.oldPassword &&
      !formData.newPassword &&
      !formData.ConfnewPassword
    ) {
      await axios
        .put(
          `admin-scope/update-account?account_id=${id}&name=${formData.name}&email=${formData.email}&phone=${formData.phone}&address=${formData.address}&lab_name=${formData.lab_name}`
        )
        .then((response) => {
          getLaps();
          setError("");
          setOpen(false);
          setErrorName("");
          setErrorNameLab("");
          setErrorEmail("");
          setErrorPhone("");
          setErrorAddress("");
          setErrorOldPassword("");
          setErrorNewPassword("");
          setErrorConfirmNewPassword("");
        })
        .catch((err) => {
          // console.log(err);
          if (err.response.status === 500) {
            Swal({
              title: "Opps, Error",
              type: "error",
              icon: "error",
              text: `The system is under maintenance, please try again later, thank you`,
            });
          } 

            if (err.response.data.errors.name) {
              setErrorName(err.response.data.errors.name[0]);
            } else {
              setErrorName("");
            }
            if (err.response.data.errors.lab_name) {
              setErrorNameLab(err.response.data.errors.lab_name[0]);
            } else {
              setErrorNameLab("");
            }

            if (err.response.data.errors.email) {
              setErrorEmail(err.response.data.errors.email[0]);
            } else {
              setErrorEmail("");
            }

            if (err.response.data.errors.phone) {
              setErrorPhone(err.response.data.errors.phone[0]);
            } else {
              setErrorPhone("");
            }

            if (err.response.data.errors.address) {
              setErrorAddress(err.response.data.errors.address[0]);
            } else {
              setErrorAddress("");
            }

            if (err.response.data.errors.old_password) {
              setErrorOldPassword(err.response.data.errors.old_password[0]);
            } else {
              setErrorOldPassword("");
            }

            if (err.response.data.errors.new_password) {
              setErrorNewPassword(err.response.data.errors.new_password[0]);
            } else {
              setErrorNewPassword("");
            }
            if (err.response.data.errors.new_password_confirmation) {
              setErrorConfirmNewPassword(
                err.response.data.errors.new_password_confirmation[0]
              );
            } else {
              setErrorConfirmNewPassword("");
            }
        });
    } else {
      await axios
        .put(
          `admin-scope/update-account?account_id=${id}&name=${formData.name}&email=${formData.email}&phone=${formData.phone}&address=${formData.address}&lab_name=${formData.lab_name}&new_password_confirmation=${formData.ConfnewPassword}&new_password=${formData.newPassword}`
          // `admin-scope/update-account?account_id=${id}&name=${formData.name}&email=${formData.email}&phone=${formData.phone}&address=${formData.address}&lab_name=${formData.lab_name}&old_password=${formData.oldPassword}&new_password_confirmation=${formData.ConfnewPassword}&new_password=${formData.newPassword}`
        )
        .then((response) => {
          setError("");
          getLaps();

          setErrorName("");
          setErrorNameLab("");
          setErrorEmail("");
          setErrorPhone("");
          setErrorAddress("");
          setErrorOldPassword("");
          setErrorNewPassword("");

          setErrorConfirmNewPassword("");
          setOpen(false);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            Swal({
              title: "Opps, Error",
              type: "error",
              icon: "error",
              text: `The system is under maintenance, please try again later, thank you`,
            });
          } else {
            if (err.response.data.error) {
              setError(err.response.data.error[0]);
            } else {
              setError("");
            }
            if (err.response.data.errors.name) {
              setErrorName(err.response.data.errors.name[0]);
            } else {
              setErrorName("");
            }
            if (err.response.data.errors.lab_name) {
              setErrorNameLab(err.response.data.errors.lab_name[0]);
            } else {
              setErrorNameLab("");
            }

            if (err.response.data.errors.email) {
              setErrorEmail(err.response.data.errors.email[0]);
            } else {
              setErrorEmail("");
            }

            if (err.response.data.errors.phone) {
              setErrorPhone(err.response.data.errors.phone[0]);
            } else {
              setErrorPhone("");
            }

            if (err.response.data.errors.address) {
              setErrorAddress(err.response.data.errors.address[0]);
            } else {
              setErrorAddress("");
            }

            if (err.response.data.errors.old_password) {
              setErrorOldPassword(err.response.data.errors.old_password[0]);
            } else {
              setErrorOldPassword("");
            }

            if (err.response.data.errors.new_password) {
              setErrorNewPassword(err.response.data.errors.new_password[0]);
            } else {
              setErrorNewPassword("");
            }
            if (err.response.data.errors.new_password_confirmation) {
              setErrorConfirmNewPassword(
                err.response.data.errors.new_password_confirmation[0]
              );
            } else {
              setErrorConfirmNewPassword("");
            }
          }
        });
    }
  };

  function close() {
    setError("");
    setErrorName("");
    setErrorNameLab("");
    setErrorEmail("");
    setErrorPhone("");
    setErrorAddress("");
    setErrorOldPassword("");
    setErrorNewPassword("");
    setErrorConfirmNewPassword("");
    setOpen(false);
  }

  const handleChangeLabName = (e) => {
    setFormData({
      ...formData,
      lab_name: e.target.value,
    });
  };
  const handleChangeName = (e) => {
    setFormData({
      ...formData,
      name: e.target.value,
    });
  };
  const handleChangeEmail = (e) => {
    setFormData({
      ...formData,
      email: e.target.value,
    });
  };
  const handleChangePhone = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setFormData({
        ...formData,
        phone: e.target.value,
      });
    }
  };
  const handleChangeAddress = (e) => {
    setFormData({
      ...formData,
      address: e.target.value,
    });
  };

  const handleChangeOldPassword = (e) => {
    setFormData({
      ...formData,
      oldPassword: e.target.value,
    });
  };

  const handleChangeNewPassword = (e) => {
    setFormData({
      ...formData,
      newPassword: e.target.value,
    });
  };

  const handleChangeConfNewPassword = (e) => {
    setFormData({
      ...formData,
      ConfnewPassword: e.target.value,
    });
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          initialFocus={cancelButtonRef}
          onClose={() => close()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto ">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-10 pb-8 bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-2 sm:max-w-xl sm:w-full ">
                  <div className="bg-white ">
                    <div className="w-full flex  h-full items-center mt-5">
                      <p className="  justify-center font-Poppins-SemiBold flex flex-grow text-lg ml-10">
                        Edit Lab
                      </p>
                      <IoIosClose
                        className=" text-4xl  text-black border-[1px] rounded-full cursor-pointer bg-[#E4E7EC] "
                        onClick={() => close()}
                      />
                    </div>

                    <div className="flex flex-col space-y-5 w-full m-auto mt-10">
                      <div className={` space-y-5 flex-col `}>
                        <div className="w-full flex flex-col justify-center items-center">
                          <img
                            src={Image}
                            className="w-40 h-40 rounded-full mx-auto"
                          />
                          {/* <input
                          id="fileInput"
                          type="file"
                          className="hidden"
                          accept="image/*"
                          onChange={(e) => getImage(e)}
                          /> */}
                          {/* <button className="bg-[#B7C835] px-2 text-white py-1 rounded-lg mt-2" onClick={filebrowser}>Click To Browse</button> */}
                        </div>
                        <div className="w-full">
                          <div className="w-full break-words border-[#E4E7EC] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-xl ">
                            <input
                              id="LabName"
                              onChange={handleChangeLabName}
                              value={formData.lab_name}
                              type="text"
                              className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errorNameLab}
                          </span>
                        </div>
                        <div className="w-full">
                          <div className="w-full break-words border-[#E4E7EC] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-xl ">
                            <input
                              id="OwnerName"
                              onChange={handleChangeName}
                              value={formData.name}
                              type="tel"
                              className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#908F8F] outline-0 ring-0"
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errorName}
                          </span>
                        </div>
                        <div>
                          <div className="w-full break-words border-[#E4E7EC] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-xl ">
                            <input
                              id="email"
                              onChange={handleChangeEmail}
                              value={formData.email}
                              type="email"
                              className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#908F8F] outline-0 ring-0"
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errorEmail}
                          </span>
                        </div>
                        <div>
                          <div className="w-full break-words border-[#E4E7EC] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-xl ">
                            <input
                              id="PhoneNumber"
                              onChange={handleChangePhone}
                              value={formData.phone}
                              type="tel"
                              className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#908F8F] outline-0 ring-0"
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errorPhone}
                          </span>
                        </div>
                        <div className="w-full">
                          <div className="w-full break-words border-[#E4E7EC] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-xl ">
                            <input
                              id="Address"
                              onChange={handleChangeAddress}
                              value={formData.address}
                              type="text"
                              className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#908F8F] outline-0 ring-0"
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errorAddress}
                          </span>
                        </div>
                        {/* <div className="w-full">
                          <div className="w-full break-words border-[#E4E7EC] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-xl ">
                            <input
                              id="oldPassword"
                              placeholder="oldPassword"
                              value={formData.oldPassword}
                              onChange={handleChangeOldPassword}
                              type="password"
                              className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#908F8F] outline-0 ring-0"
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errorOldPassword}
                          </span>
                          <span className="text-sm text-red-600">{error}</span>
                        </div> */}

                        <div className="w-full">
                          <div className="w-full break-words border-[#E4E7EC] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-xl ">
                            <input
                              id="Newpassword"
                              placeholder="New Password"
                              value={formData.newPassword}
                              onChange={handleChangeNewPassword}
                              type="password"
                              className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#908F8F] outline-0 ring-0"
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errorNewPassword}
                          </span>
                        </div>

                        <div className="w-full">
                          <div className="w-full break-words border-[#E4E7EC] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-xl ">
                            <input
                              id="CofirmNewpassword"
                              placeholder="Confirm New password"
                              value={formData.ConfnewPassword}
                              onChange={handleChangeConfNewPassword}
                              type="password"
                              className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#908F8F] outline-0 ring-0"
                            />
                          </div>
                          <span className="text-sm text-red-600">
                            {errorConfirmNewPassword}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white  py-3  flex sm:flex-row-reverse justify-between mt-10">
                    <button
                      type="button"
                      className="flex flex-grow font-medium text-sm py-3 bg-[#B7C835] justify-center rounded-xl text-white"
                      onClick={() => Edit()}
                    >
                      Edit Lab
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
