import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { VscListFlat } from "react-icons/vsc";
import SideBarSystemDB from "../components/SystemDashBoard/SideBarSystemDB";
import HeaderSystemDB from "../components/SystemDashBoard/HeaderSystemDB";
import axios from "axios";
import Swal from "sweetalert";
function ProfileSDB() {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    ConfnewPassword: "",
  });
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState("");
  const [error, setError] = useState("");

  const GetProfile = async () => {
    await axios
      .get(`admin-scope/get-admin`)
      .then((response) => {
        setFormData({
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const Change = async () => {
    if (
      !formData.oldPassword &&
      !formData.newPassword &&
      !formData.ConfnewPassword
    ) {
      await axios
        .put(
          `admin-scope/auth-profile?email=${formData.email}&name=${formData.name}`
        )
        .then((response) => {
          Swal({
            title: "Good job!",
            type: "success",
            icon: "success",
            text: `${response.data.message[0]}`,
          });
          setError("");
          formData({
            ...formData,
            oldPassword: "",
            newPassword: "",
            ConfnewPassword: "",
          });
          setErrorName("");
          setErrorEmail("");
          setErrorOldPassword("");
          setErrorNewPassword("");
          setErrorConfirmNewPassword("");
          GetProfile();
        })
        .catch((err) => {
          if (error.response.status === 500) {
            Swal({
              title: "Opps, Error",
              type: "error",
              icon: "error",
              text: `The system is under maintenance, please try again later, thank you`,
            });
          } else {
            if (err.response.data.errors.name) {
              setErrorName(err.response.data.errors.name[0]);
            } else {
              setErrorName("");
            }
            if (err.response.data.errors.email) {
              setErrorEmail(err.response.data.errors.email[0]);
            } else {
              setErrorEmail("");
            }
            if (err.response.data.errors.old_password) {
              setErrorOldPassword(err.response.data.errors.old_password[0]);
            } else {
              setErrorOldPassword("");
            }
            if (err.response.data.errors.new_password) {
              setErrorNewPassword(err.response.data.errors.new_password[0]);
            } else {
              setErrorNewPassword("");
            }
            if (err.response.data.errors.new_password_confirmation) {
              setErrorConfirmNewPassword(
                err.response.data.errors.new_password_confirmation[0]
              );
            } else {
              setErrorConfirmNewPassword("");
            }
          }
        });
    } else {
      await axios
        .put(
          `admin-scope/auth-profile?email=${formData.email}&name=${formData.name}&old_password=${formData.oldPassword}&new_password=${formData.newPassword}&new_password_confirmation=${formData.ConfnewPassword}`
        )
        .then((response) => {
          setError("");
          Swal({
            title: "Good job!",
            type: "success",
            icon: "success",
            text: `${response.data.message[0]}`,
          });
          formData({
            oldPassword: "",
            newPassword: "",
            ConfnewPassword: "",
          });
          setErrorName("");
          setErrorEmail("");
          setErrorOldPassword("");
          setErrorNewPassword("");
          setErrorConfirmNewPassword("");
          GetProfile();
        })
        .catch((err) => {
          if (error.response.status === 500) {
            Swal({
              title: "Opps, Error",
              type: "error",
              icon: "error",
              text: `The system is under maintenance, please try again later, thank you`,
            });
          } else {
            if (err.response.data.message) {
              setError(err.response.data.message[0]);
            } else {
              setError("");
            }
            if (err.response.data.errors.name) {
              setErrorName(err.response.data.errors.name[0]);
            } else {
              setErrorName("");
            }
            if (err.response.data.errors.email) {
              setErrorEmail(err.response.data.errors.email[0]);
            } else {
              setErrorEmail("");
            }
            if (err.response.data.errors.old_password) {
              setErrorOldPassword(err.response.data.errors.old_password[0]);
            } else {
              setErrorOldPassword("");
            }
            if (err.response.data.errors.new_password) {
              setErrorNewPassword(err.response.data.errors.new_password[0]);
            } else {
              setErrorNewPassword("");
            }
            if (err.response.data.errors.new_password_confirmation) {
              setErrorConfirmNewPassword(
                err.response.data.errors.new_password_confirmation[0]
              );
            } else {
              setErrorConfirmNewPassword("");
            }
          }
        });
    }
  };

  const handleChangeName = (e) => {
    setFormData({
      ...formData,
      name: e.target.value,
    });
  };
  const handleChangeEmail = (e) => {
    setFormData({
      ...formData,
      email: e.target.value,
    });
  };

  const handleChangeOldPassword = (e) => {
    setFormData({
      ...formData,
      oldPassword: e.target.value,
    });
  };

  const handleChangeNewPassword = (e) => {
    setFormData({
      ...formData,
      newPassword: e.target.value,
    });
  };

  const handleChangeConfNewPassword = (e) => {
    setFormData({
      ...formData,
      ConfnewPassword: e.target.value,
    });
  };
  return (
    <div className="w-full h-full pr-5 p-5">
      <div className="w-full flex ">
        <HeaderSystemDB />
      </div>
      <div className="flex ">
        <SideBarSystemDB page="Profile" />
        <div className="w-full h-full lg:ml-8 mt-10 pb-48">
          {/* Pangration */}
          <div className={`  mt-10 mb-10`}>
            <div className="grid grid-cols-2 gap-5 w-full justify-between ">
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    id="email"
                    placeholder="Email"
                    onChange={handleChangeEmail}
                    value={formData.email}
                    type="email"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                </div>
                <span className="text-sm text-red-600">{errorEmail}</span>
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    id="Name"
                    placeholder="Name"
                    onChange={handleChangeName}
                    value={formData.name}
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                </div>
                <span className="text-sm text-red-600">{errorName}</span>
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    id="oldPassword"
                    placeholder="oldPassword"
                    value={formData.oldPassword}
                    onChange={handleChangeOldPassword}
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                </div>
                <span className="text-sm text-red-600">{errorOldPassword}</span>
                <span className="text-sm text-red-600">{error}</span>
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    id="Newpassword"
                    placeholder="New Password"
                    value={formData.newPassword}
                    onChange={handleChangeNewPassword}
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                </div>
                <span className="text-sm text-red-600">{errorNewPassword}</span>
              </div>
              <div className="w-full">
                <div className="border-[#E4E7EC] w-full h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    id="ConfirmPassword"
                    placeholder="Confirm password"
                    value={formData.ConfnewPassword}
                    onChange={handleChangeConfNewPassword}
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                </div>
                <span className="text-sm text-red-600">
                  {errorConfirmNewPassword}
                </span>
              </div>

              <div
                onClick={() => Change()}
                className="col-start-1 col-end-3 ml-auto bg-[#0D2135] w-[30%] lg:w-[20%]  flex items-center justify-center px-5 lg:px-10  py-2 rounded-xl cursor-pointer "
              >
                <p className="text-sm flex items-center justify-center text-center text-white font-Poppins-Medium">
                  Change
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSDB;
