import React, { useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { BsArrowDown } from "react-icons/bs";
import { BsArrowUp } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FcFolder } from "react-icons/fc";
import { FcOpenedFolder } from "react-icons/fc";
import { AiFillPlusCircle } from "react-icons/ai";

import { VscListFlat } from "react-icons/vsc";
import { Link } from "react-router-dom";
import AddSectionSystemDB from "../components/SystemDashBoard/Analytic/AddSectionSystemDB";
import IntrputikSystemDB from "../components/SystemDashBoard/Analytic/IntrputikSystemDB";
import HeaderSystemDB from "../components/SystemDashBoard/HeaderSystemDB";
import SideBarSystemDB from "../components/SystemDashBoard/SideBarSystemDB";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllDBAnalysis,
  selectDBanalysis,
} from "../GlobalData/SystemDashBoard/AnalysisSBDSlice";
import IntrputikChildSystemDB from "../components/SystemDashBoard/Analytic/IntrputikChildSystemDB";
import {
  addAllChildDBanalysis,
  selectChildDBanalysis,
} from "../GlobalData/SystemDashBoard/AnalysisChildSBDSlice";
import EditSection from "../components/SystemDashBoard/Analytic/EditSection";
import EditIntrputik from "../components/SystemDashBoard/Analytic/EditIntrputik";
import { GoDash } from "react-icons/go";

function AnalyticSystemDB() {
  {
    /* i was doing redux for the manereport and Culture Report tables  */
    /* i did just the get Sections api  */
  }
  const [ShowAddSectionSystemDB, SetShowAddSectionSystemDB] = useState(false);
  const [ShowAddSubSectionSystemDB, SetShowAddSubSectionSystemDB] =
    useState(false);
  const [id, SetId] = useState(-1);
  const DBAnalysisSelector = useSelector(selectDBanalysis);
  const ChildDBAnalysisSelector = useSelector(selectChildDBanalysis);
  const [ShowEditSection, SetShowEditSection] = useState(false);
  const [showEditIntrputik, SetShowEditIntrputik] = useState(false);
  const [DataBeforeFilter, setDataBeforeFilter] = useState();
  const [type, setType] = useState("Parent");
  const [SectionId, setSectionId] = useState();

    const [UpDisable, setUpDisable] = useState(false);
  const [DownDisable, setDownDisable] = useState(false);

  const [section, setSection] = useState("Parent");

  const dispatch = useDispatch();

  const getSections = async () => {
    await axios.get(`admin-scope/sections`).then((response) => {
      dispatch(addAllDBAnalysis(response.data));
      setDataBeforeFilter(response.data);
    });
  };
  useEffect(() => {
    getSections();
  }, []);

  {
    /* here when u click on the folder he will make some effect */
  }
  const SectionClicked = async (id, once) => {
    setUpDisable(false);
    setDownDisable(false);
    setSection("Parent");
    setType("Parent");

    SetId(id);
    setSectionId(id);
    SetShowAddSectionSystemDB(false);
    SetShowAddSubSectionSystemDB(false);
    SetShowEditIntrputik(false);
    SetShowEditSection(true);
    if (once === false) {
      if (
        document
          .getElementById(`ArrowForward-${id}`)
          .classList.contains("hidden")
      ) {
        document
          .getElementById(`ArrowForward-${id}`)
          .classList.remove("hidden");
        document
          .getElementById(`ClosedFolder-${id}`)
          .classList.remove("hidden");

        document.getElementById(`ArrowBack-${id}`).classList.add("hidden");
        document.getElementById(`OpenedFolder-${id}`).classList.add("hidden");
        document.getElementById(`SubList-${id}`).classList.add("hidden");
      } else {
        await axios
          .get(`admin-scope/getAnalys?section_id=${id}`)
          .then((response) => {
            dispatch(addAllChildDBanalysis(response.data));
            for (let i = 0; i < DBAnalysisSelector.length; i++) {
              if (
                DBAnalysisSelector[i].id !== id &&
                DBAnalysisSelector[i].once === false
              ) {
                if (
                  document
                    .getElementById(`ArrowForward-${DBAnalysisSelector[i].id}`)
                    .classList.contains("hidden")
                ) {
                  document
                    .getElementById(`ArrowForward-${DBAnalysisSelector[i].id}`)
                    .classList.remove("hidden");
                  document
                    .getElementById(`ClosedFolder-${DBAnalysisSelector[i].id}`)
                    .classList.remove("hidden");

                  document
                    .getElementById(`ArrowBack-${DBAnalysisSelector[i].id}`)
                    .classList.add("hidden");
                  document
                    .getElementById(`OpenedFolder-${DBAnalysisSelector[i].id}`)
                    .classList.add("hidden");
                  document
                    .getElementById(`SubList-${DBAnalysisSelector[i].id}`)
                    .classList.add("hidden");
                }
              }
            }
            if (
              document
                .getElementById(`ArrowForward-${id}`)
                .classList.contains("hidden")
            ) {
              document
                .getElementById(`ArrowForward-${id}`)
                .classList.remove("hidden");
              document
                .getElementById(`ClosedFolder-${id}`)
                .classList.remove("hidden");

              document
                .getElementById(`ArrowBack-${id}`)
                .classList.add("hidden");
              document
                .getElementById(`OpenedFolder-${id}`)
                .classList.add("hidden");
              document.getElementById(`SubList-${id}`).classList.add("hidden");
            } else if (
              !document
                .getElementById(`ArrowForward-${id}`)
                .classList.contains("hidden")
            ) {
              document
                .getElementById(`ArrowBack-${id}`)
                .classList.remove("hidden");
              document
                .getElementById(`OpenedFolder-${id}`)
                .classList.remove("hidden");
              document
                .getElementById(`SubList-${id}`)
                .classList.remove("hidden");

              document
                .getElementById(`ArrowForward-${id}`)
                .classList.add("hidden");
              document
                .getElementById(`ClosedFolder-${id}`)
                .classList.add("hidden");
            }
          });
      }
    }
  };
  const SectionAdd = () => {
    setSection("Parent");

    SetShowAddSectionSystemDB(true);
    SetShowAddSubSectionSystemDB(false);
    SetShowEditIntrputik(false);
    SetShowEditSection(false);
  };

  const SubSictionShow = (id) => {

    setSectionId(id);
    SetShowAddSectionSystemDB(false);
    SetShowAddSubSectionSystemDB(true);
    SetShowEditIntrputik(false);
    SetShowEditSection(false);
    SetId(id);
  };

  const ShowSection = (id) => {
    SetId(id);
    SetShowAddSectionSystemDB(false);
    SetShowAddSubSectionSystemDB(false);
    SetShowEditIntrputik(false);
    SetShowEditSection(true);
  };
  const ShowEditAnalysis = (id, type) => {
     setUpDisable(false);
    setDownDisable(false);
     setSection(type);
    setType(type);
    SetId(id);
    SetShowAddSectionSystemDB(false);
    SetShowAddSubSectionSystemDB(false);
    SetShowEditSection(false);
    SetShowEditIntrputik(true);
  };

  const sortZ = () => {
    if (id !== -1 && section == "Parent") {
      setDownDisable(false);

      let ids = [];

      for (let i = 0; i < DBAnalysisSelector.length; i++) {
        ids[i] = {
          id: DBAnalysisSelector[i].id,
          once: DBAnalysisSelector[i].once,
        };
      }
      for (let i = 0; i < ids.length; i++) {
        if (ids[i].once == true) {
          ids[i] = {
            id: DBAnalysisSelector[i].id,
            once: DBAnalysisSelector[i].once,
          };
        }
        if (ids[i].once == false)
          ids[i] = {
            id: DBAnalysisSelector[i].id,
            once: DBAnalysisSelector[i].once,
          };
      }

      if (ids[0].id !== id) {
        let index = ids.findIndex((analys) => analys.id == id);
        if (index !== ids.length) {
          console.log(ids[index].once == false);

          if (ids[index - 1].once == false) {
            console.log(ids[index].id);
            if (index !== ids.length) {
              SetId(ids[index - 1].id);
              SetShowAddSectionSystemDB(false);
              SetShowAddSubSectionSystemDB(false);
              SetShowEditIntrputik(false);
              SetShowEditSection(true);
            }
            if (index - 1 == 0) {
              setUpDisable(true);
            }
          }
          if (ids[index - 1].once == true) {
            if (index !== ids.length) {
              SetId(ids[index - 1].id);
              SetShowAddSectionSystemDB(false);
              SetShowAddSubSectionSystemDB(false);
              SetShowEditSection(false);
              SetShowEditIntrputik(true);
            }
            if (index - 1 == 0) {
              setUpDisable(true);
            }
          }
        }
      }
      // if (DBAnalysisSelector) {
      //   let arrayForSort = [...DBAnalysisSelector];
      //   arrayForSort.sort(function (a, b) {
      //     if (a.name.toLowerCase() < b.name.toLowerCase()) {
      //       return -1;
      //     }
      //     if (a.name.toLowerCase() > b.name.toLowerCase()) {
      //       return 1;
      //     }
      //     return 0;
      //   });
      //   dispatch(addAllAnalysis(arrayForSort));
      // }
    }
  };

  const sortA = () => {
    if (id == -1 && section == "Parent") {
      setUpDisable(false);
      let ids = [];

      for (let i = 0; i < DBAnalysisSelector.length; i++) {
        ids[i] = {
          id: DBAnalysisSelector[i].id,
          once: DBAnalysisSelector[i].once,
        };
      }
      for (let i = 0; i < ids.length; i++) {
        if (ids[i].once == true) {
          ids[i] = {
            id: DBAnalysisSelector[i].id,
            once: DBAnalysisSelector[i].once,
          };
        }
        if (ids[i].once == false)
          ids[i] = {
            id: DBAnalysisSelector[i].id,
            once: DBAnalysisSelector[i].once,
          };
      }

      if (ids[ids.length - 1].id !== DBAnalysisSelector[0].id) {
        let index = ids.findIndex(
          (analys) => analys.id == DBAnalysisSelector[0].id
        );
        if (index !== ids.length) {
          // console.log(ids[index].once == false);

          if (ids[index].once == false) {
            // console.log(ids[index].id);
            if (index !== ids.length) {
              // console.log(ids[index].id);

              SetId(ids[index].id);
              SetShowAddSectionSystemDB(false);
              SetShowAddSubSectionSystemDB(false);
              SetShowEditIntrputik(false);
              SetShowEditSection(true);
            }
            if (index == ids.length - 1) {
              setDownDisable(true);
            }
          }
          if (ids[index].once == true) {
            if (index !== ids.length) {
              // console.log(ids[index].id);

              SetId(ids[index].id);
              SetShowAddSectionSystemDB(false);
              SetShowAddSubSectionSystemDB(false);
              SetShowEditSection(false);
              SetShowEditIntrputik(true);
            }
            if (index == ids.length - 1) {
              setDownDisable(true);
            }
          }
        }
      }
    }





     if (id !== -1 && section == "Parent") {
      setUpDisable(false);
      let ids = [];

      for (let i = 0; i < DBAnalysisSelector.length; i++) {
        ids[i] = {
          id: DBAnalysisSelector[i].id,
          once: DBAnalysisSelector[i].once,
        };
      }
      for (let i = 0; i < ids.length; i++) {
        if (ids[i].once == true) {
          ids[i] = {
            id: DBAnalysisSelector[i].id,
            once: DBAnalysisSelector[i].once,
          };
        }
        if (ids[i].once == false)
          ids[i] = {
            id: DBAnalysisSelector[i].id,
            once: DBAnalysisSelector[i].once,
          };
      }

      if (ids[ids.length - 1].id !== id) {
        let index = ids.findIndex((analys) => analys.id == id);
        if (index !== ids.length) {
          console.log(ids[index].once == false);

          if (ids[index + 1].once == false) {
            console.log(ids[index].id);
            if (index !== ids.length) {
              SetId(ids[index + 1].id);
              SetShowAddSectionSystemDB(false);
              SetShowAddSubSectionSystemDB(false);
              SetShowEditIntrputik(false);
              SetShowEditSection(true);
            }
            if (index + 1 == ids.length - 1) {
              setDownDisable(true);
            }
          }
          if (ids[index + 1].once == true) {
            if (index !== ids.length) {
              SetId(ids[index + 1].id);
              SetShowAddSectionSystemDB(false);
              SetShowAddSubSectionSystemDB(false);
              SetShowEditSection(false);
              SetShowEditIntrputik(true);
            }
            if (index + 1 == ids.length - 1) {
              setDownDisable(true);
            }
          }
        }
      }

      // console.log(index, "current");

      // console.log(ids.length, "after");
      // if (DBAnalysisSelector) {
      //   let arrayForSort = [...DBAnalysisSelector];
      //   arrayForSort.sort(function (a, b) {
      //     if (a.name.toLowerCase() > b.name.toLowerCase()) {
      //       return -1;
      //     }
      //     if (a.name.toLowerCase() < b.name.toLowerCase()) {
      //       return 1;
      //     }
      //     return 0;
      //   });
      //   dispatch(addAllAnalysis(arrayForSort));
      // }
    }
  };
  return (
    <div className="w-full h-full pr-5 p-5">
      <div className="w-full flex ">
        <HeaderSystemDB
          section="analytic"
          DataBeforeFilter={DataBeforeFilter}
        />
      </div>
      <div className="flex ">
        <SideBarSystemDB page="Analytic" />
        <div className="w-full h-full lg:ml-8 mt-10">
          <div className="flex flex-col h-full md:flex-row">
            <div className="bg-white p-5 lg:w-[20rem] h-full   w-full flex flex-col rounded-2xl items-center ">
              <div
                className="bg-[#B7C835] w-fit  flex items-center justify-center px-10 py-3 rounded-xl cursor-pointer "
                onClick={() => SectionAdd()}
              >
                <p className="text-sm font-Poppins-Regular flex items-center justify-center text-white">
                  <AiOutlineFolderAdd className="mr-2 text-3xl" />
                  Add section
                </p>
              </div>
              <div className="flex justify-between w-full  px-3 mt-5">
                <div
                  disabled={DownDisable}
                  
                  className={`${
                    DownDisable ? "text-gray-500" : "cursor-pointer"
                  } flex items-center space-x-2 `}
                  onClick={() => sortA()}
                >
                  <BsArrowDown />
                  <p className="font-Poppins-Regular text-sm">Move down</p>
                </div>
                <div
                  disabled={UpDisable}
                  
                  className={`${
                    UpDisable ? "text-gray-500" : "cursor-pointer"
                  } flex items-center space-x-2 `}
                  onClick={() => sortZ()}
                >
                  <BsArrowUp />
                  <p className="font-Poppins-Regular text-sm">Move up</p>
                </div>
              </div>
              {/*h-[70rem] overflow-y-scroll  DBAnalysisSelector Redux it has the whole data for getSections*/}
              <div className="h-fit max-h-[50rem]  overflow-y-scroll w-full scrollbar-hide mt-5">
                {DBAnalysisSelector &&
                  DBAnalysisSelector.map((Analysis) =>
                    Analysis.once == false ? (
                      <div className="w-full ">
                        <div
                          className={`${
                            id == Analysis.id && ShowEditSection == true
                              ? "border-[#B7C835] border-[1px]"
                              : " "
                          } flex pl-4   cursor-pointer mt-5 bg-[#F1F4D7] rounded-lg w-full py-2 px-3 items-center`}
                        >
                          <div
                            onClick={() =>
                              SectionClicked(Analysis.id, Analysis.once)
                            }
                            className="cursor-pointer flex  bg-[#F1F4D7] rounded-lg w-full py-2 px-3 items-center"
                          >
                            <IoIosArrowForward
                              id={`ArrowForward-${Analysis.id}`}
                              className={`${
                                Analysis.once === true ? "hidden" : ""
                              }   mr-2`}
                            />
                            <IoIosArrowDown
                              id={`ArrowBack-${Analysis.id}`}
                              className="mr-2 hidden"
                            />

                            <div className="flex  items-center ">
                              <FcFolder
                                id={`ClosedFolder-${Analysis.id}`}
                                className="text-2xl mr-2"
                              />
                              <FcOpenedFolder
                                id={`OpenedFolder-${Analysis.id}`}
                                className="text-2xl mr-2 hidden"
                              />

                              <p className="font-Poppins-Medium text-sm">
                                {Analysis.name}
                              </p>
                            </div>
                          </div>
                          {/* <div className="bg-white rounded-full mr-2">
                            <GoDash
                              className={`${
                                Analysis.once === true ? "hidden" : ""
                              } text-yellow-400 text-lg `}
                              onClick={() => ShowSection(Analysis.id)}
                            />
                          </div> */}
                          <AiFillPlusCircle
                            className={`${
                              Analysis.once === true ? "hidden" : ""
                            } ${
                              Analysis.id == SectionId &&
                              ShowAddSubSectionSystemDB == true
                                ? "text-yellow-500"
                                : "text-[#B7C835] "
                            }  text-xl`}
                            onClick={() => SubSictionShow(Analysis.id)}
                          />
                        </div>

                        <div id={`SubList-${Analysis.id}`} className="hidden ">
                          <div className="w-full mt-3">
                            <ul className="flex space-y-2 flex-col justify-center items-center w-full">
                              {ChildDBAnalysisSelector.data?.length > 0 &&
                                ChildDBAnalysisSelector.data.map((Analysi) => (
                                  <li
                                    className={`${
                                      id == Analysi.id
                                        ? "border-[#B7C835] border-[1px]"
                                        : " "
                                    } bg-[#F1F4D7] w-full py-2 text-center rounded-xl cursor-pointer`}
                                    onClick={() =>
                                      ShowEditAnalysis(Analysi.id, "Child")
                                    }
                                  >
                                    {Analysi.name}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w-full ">
                        <div
                          className={`flex pl-4  ${
                            id == Analysis.id
                              ? "border-[#B7C835] border-[1px]"
                              : " "
                          } cursor-pointer mt-5 bg-[#F1F4D7] rounded-lg w-full py-2 px-3 items-center`}
                          onClick={() =>
                            ShowEditAnalysis(Analysis.id, "Parent")
                          }
                        >
                          <div
                            className={`flex  bg-[#F1F4D7] rounded-lg w-full py-2 px-3 items-center`}
                          >
                            <div className="flex  items-center ">
                              <FcFolder
                                id={`ClosedFolder`}
                                className="text-2xl mr-2"
                              />

                              <p className="font-Poppins-Medium text-sm">
                                {Analysis.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>

            <div className="md:w-3/4 w-full  mt-5 md:ml-8  md:mt-0 ">
              <AddSectionSystemDB
                setID={SetId}
                setDataBeforeFilter={setDataBeforeFilter}
                ide={-1}
                show={ShowAddSectionSystemDB}
              />
              <IntrputikChildSystemDB
                setDataBeforeFilter={setDataBeforeFilter}
                ide={id}
                show={ShowAddSubSectionSystemDB}
              />

              <EditSection
                setDataBeforeFilter={setDataBeforeFilter}
                
                id={id}
                show={ShowEditSection}
                setShow={SetShowEditSection}
              />
              <EditIntrputik
                setDataBeforeFilter={setDataBeforeFilter}
                
                type={type}
                ide={id}
                show={showEditIntrputik}
                setShow={SetShowEditIntrputik}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticSystemDB;
