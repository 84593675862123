import React, { useState } from "react";
import { TiEdit } from "react-icons/ti";
import { IoTrashOutline } from "react-icons/io5";
// import { t } from "i18next";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { addAllDBAnalysis } from "../../../../GlobalData/SystemDashBoard/AnalysisSBDSlice";
import {
  addAllEditCultureReport,
  addToEditCultureReport,
  selectEditCultureReports,
} from "../../../../GlobalData/SystemDashBoard/EditCultureReportSlice";
import EditShowCultureReport from "./EditCultureReport/EditShowCultureReport";
import DeleteShowCultureReport from "./EditCultureReport/DeleteShowCultureReport";
import Swal from "sweetalert";
function ShowCultureReport({
  setCulturReportErrorRedux,
  culturReportErrorRedux,
    setGenderError,
  setNormalRangeError,
  setHighError,
  setLowError,
  setDataBeforeFilter,
  ide,
  Relation,
  setShow,
  setHError,
  setMError,
  Cerror,
  SetOpenDelete,
  setCError,
  setError,
  setErrorName,
  setErrorTupe,
  setErrorTestCode,
  setErrorPriceForPatient,
  setErrorPriceForLap,
  setErrorPriceForCompany,
  setErrorTestMethod,
  setErrorTestUnit,
  formData,
  setFormData,
  type,
  intrputik,
}) {
  const CultureReportSelector = useSelector(selectEditCultureReports);
  const dispatch = useDispatch();
  const [Ready, setReady] = useState(true);
  const [text, setText] = useState("");
  const [OpenEditCultureReport, setOpenEditCultureReport] = useState(false);
  const [OpenDeleteCultureReport, setOpenDeleteCultureReport] = useState(false);
  const getSections = async (page) => {
    await axios.get(`admin-scope/sections`).then((response) => {
      dispatch(addAllDBAnalysis(response.data));
      setDataBeforeFilter(response.data)
    });
  };
  const [id, SetId] = useState(0);
  const Edit = (id) => {
    SetId(id);
    setOpenEditCultureReport(true);
  };
  const Delete = (id) => {
    SetId(id);
    setOpenDeleteCultureReport(true);
  };
  const AddToTable = () => {
    let Data = {
      id: CultureReportSelector.length + 1,
      text: document.getElementById("ShowsubjectTitle").value,
    };
    if (!Data.text) {
      // document.getElementById("Title_error").classList.remove("invisible");\
      setCulturReportErrorRedux(true);

    } else {
      setCulturReportErrorRedux(false);

      // document.getElementById("Title_error").classList.add("invisible");
    }
    if (Data.text) {
      dispatch(addToEditCultureReport(Data));
      setText("");
    }
  };
  const textChange = (e) => {
    setText(e.target.value);
  };
  const Save = (e) => {
    if (Relation === "Parent") {
      setReady(false);
      axios
        .post(`admin-scope/update-MainAnalys?section_id=${ide}`, {
          once: 1,
          test_code: formData.test_code,
          mainAnalys: true,
          test_print_name: formData.test_print_name,
          price_for_patient: formData.price_for_patient,
          price_for_lap: formData.price_for_lap,
          price_for_company: formData.price_for_company,
          test_method_id: formData.test_method_id,
          test_unit_id: formData.test_unit_id,
          tupe_id: formData.tupe_id,
          antibiotic: 0,
          culture_report: CultureReportSelector,
        })
        .then((res) => {
          getSections();
          setCError("");
          setHError("");
          setMError("");
          setShow(false);
          // setText("");
          // dispatch(addAllEditCultureReport([]));
          setReady(true);

          // setFormData({
          //   once: 1,
          //   test_code: "",
          //   test_print_name: "",
          //   price_for_patient: "",
          //   price_for_lap: "",
          //   price_for_company: "",
          //   test_method_id: -1,
          //   test_unit_id: -1,
          //   class_report: "",
          //   tupe_id: -1,
          // });
          setErrorName("");

          setErrorTestCode("");
          setErrorPriceForPatient("");
          setErrorPriceForLap("");
          setErrorPriceForCompany("");
          setErrorTestMethod("");
          setErrorTestUnit("");
          setErrorTupe("");
          // setError("");
          setGenderError(false);
          setNormalRangeError(false);
          setHighError(false);
                  setLowError(false);
                  setCulturReportErrorRedux(false);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            Swal({
              title: "Opps, Error",
              type: "error",
              icon: "error",
              text: `The system is under maintenance, please try again later, thank you`,
            });
          } else {
            const array = err.response.data.errors;

            if (array["culture_report"]) {
              setCError(array["culture_report"][0]);
            }
            setReady(true);

            // dispatch(getErrors());
            if (err.response.data.errors.test_print_name) {
              setErrorName(err.response.data.errors.test_print_name[0]);
            } else {
              setErrorName("");
            }
            if (err.response.data.errors.test_code) {
              setErrorTestCode(err.response.data.errors.test_code[0]);
            } else {
              setErrorTestCode("");
            }
            if (err.response.data.errors.price_for_patient) {
              setErrorPriceForPatient(
                err.response.data.errors.price_for_patient[0]
              );
            } else {
              setErrorPriceForPatient("");
            }
            if (err.response.data.errors.price_for_lap) {
              setErrorPriceForLap(err.response.data.errors.price_for_lap[0]);
            } else {
              setErrorPriceForLap("");
            }
            if (err.response.data.errors.price_for_company) {
              setErrorPriceForCompany(
                err.response.data.errors.price_for_company[0]
              );
            } else {
              setErrorPriceForCompany("");
            }
            if (err.response.data.errors.test_method_id) {
              setErrorTestMethod(err.response.data.errors.test_method_id[0]);
            } else {
              setErrorTestMethod("");
            }
            if (err.response.data.errors.test_unit_id) {
              setErrorTestUnit(err.response.data.errors.test_unit_id[0]);
            } else {
              setErrorTestUnit("");
            }
            if (err.response.data.errors.tupe_id) {
              setErrorTupe(err.response.data.errors.tupe_id[0]);
            } else {
              setErrorTupe("");
            }
            swal("Oh noes!", `${err.response.data.message}`, "error");
          }
        });
    } else if (Relation === "Child") {
      setReady(false);
      axios
        .post(`admin-scope/update-MainAnalys?analys_id=${ide}`, {
          once: 1,
          test_code: formData.test_code,
          test_print_name: formData.test_print_name,
          price_for_patient: formData.price_for_patient,
          price_for_lap: formData.price_for_lap,
          price_for_company: formData.price_for_company,
          test_method_id: formData.test_method_id,
          test_unit_id: formData.test_unit_id,
          tupe_id: formData.tupe_id,
          antibiotic: 0,
          culture_report: CultureReportSelector,
        })
        .then((res) => {
          setReady(true);

          getSections();
          setCError("");
          setShow(false);
          // setText("");
          // dispatch(addAllEditCultureReport([]));

          // setFormData({
          //   once: 1,
          //   test_code: "",
          //   test_print_name: "",
          //   price_for_patient: "",
          //   price_for_lap: "",
          //   price_for_company: "",
          //   test_method_id: -1,
          //   test_unit_id: -1,
          //   class_report: "",
          //   tupe_id: -1,
          // });
          setErrorName("");

          setErrorTestCode("");
          setErrorPriceForPatient("");
          setErrorPriceForLap("");
          setErrorPriceForCompany("");
          setErrorTestMethod("");
          setErrorTestUnit("");
          setErrorTupe("");
          // setError("");
          setGenderError(false);
          setNormalRangeError(false);
          setHighError(false);
                  setLowError(false);
                  setCulturReportErrorRedux(false);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            Swal({
              title: "Opps, Error",
              type: "error",
              icon: "error",
              text: `The system is under maintenance, please try again later, thank you`,
            });
          } else {
            setReady(true);

            const array = err.response.data.errors;

            if (array["culture_report"]) {
              setCError(array["culture_report"][0]);
            }

            // dispatch(getErrors());
            if (err.response.data.errors.test_print_name) {
              setErrorName(err.response.data.errors.test_print_name[0]);
            } else {
              setErrorName("");
            }
            if (err.response.data.errors.test_code) {
              setErrorTestCode(err.response.data.errors.test_code[0]);
            } else {
              setErrorTestCode("");
            }
            if (err.response.data.errors.price_for_patient) {
              setErrorPriceForPatient(
                err.response.data.errors.price_for_patient[0]
              );
            } else {
              setErrorPriceForPatient("");
            }
            if (err.response.data.errors.price_for_lap) {
              setErrorPriceForLap(err.response.data.errors.price_for_lap[0]);
            } else {
              setErrorPriceForLap("");
            }
            if (err.response.data.errors.price_for_company) {
              setErrorPriceForCompany(
                err.response.data.errors.price_for_company[0]
              );
            } else {
              setErrorPriceForCompany("");
            }
            if (err.response.data.errors.test_method_id) {
              setErrorTestMethod(err.response.data.errors.test_method_id[0]);
            } else {
              setErrorTestMethod("");
            }
            if (err.response.data.errors.test_unit_id) {
              setErrorTestUnit(err.response.data.errors.test_unit_id[0]);
            } else {
              setErrorTestUnit("");
            }
            if (err.response.data.errors.tupe_id) {
              setErrorTupe(err.response.data.errors.tupe_id[0]);
            } else {
              setErrorTupe("");
            }
            swal("Oh noes!", `${err.response.data.message}`, "error");
          }
        });
    }
  };

  const Remove = () => {
    SetOpenDelete(true);
  };
  return (
    <div
      className={`${
        type === "CultureReport" ? "block" : "hidden"
      } col-start-1 col-end-3 mt-10 mb-10`}
    >
      <EditShowCultureReport
        id={id}
        open={OpenEditCultureReport}
        setOpen={setOpenEditCultureReport}
      />
      <DeleteShowCultureReport
        id={id}
        open={OpenDeleteCultureReport}
        setOpen={setOpenDeleteCultureReport}
      />
      <div className="flex w-full justify-start space-x-10 col-start-1 col-end-3">
        <div className="w-full">
          <div className="border-[#E4E7EC] w-[50%] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
            <input
              id="ShowsubjectTitle"
              onChange={(e) => textChange(e)}
              value={text}
              placeholder={"subject title"}
              type="text"
              className="w-full bg-[#F9FAFF] font-Poppins-Regular text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
            />
          </div>
          <span
            id="ShowTitle_error"
            className={`${culturReportErrorRedux?"":"invisible"}  ml-1 text-red-600 text-xs font-Poppins-Regular`}
          >
            {"Please Enter Title"}
          </span>
        </div>
        <div
          onClick={() => AddToTable()}
          className=" bg-[#0D2135] w-[34%] h-fit  flex items-center justify-center lg:px-10 text-center py-2 rounded-xl cursor-pointer "
        >
          <p className="text-sm flex items-center justify-center text-white font-Poppins-Regular">
            {"Add to table"}
          </p>
        </div>
      </div>

      <table className="w-full h-full mt-5  bg-white  rounded-2xl col-start-1 col-end-3 ">
        <tr className="border-b-[1px] w-full">
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-1 pl-8 w-[85%]">
            {"First entry box"}
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-1 w-[15%]">
            {"Action"}
          </td>
        </tr>
        {CultureReportSelector
          ? CultureReportSelector.map((Report) => (
              <tr className="border-b-[1px] w-full">
                <td className="text-sm text-[#101828] font-semibold font-Poppins-Regular py-1 pl-8 ">
                  {Report.text}
                </td>
                <td className="text-sm text-[#101828] font-semibold font-Poppins-Regular py-1 ">
                  <div className="flex space-x-2 ">
                    <TiEdit
                      className="text-2xl  opacity-50 cursor-pointer"
                      onClick={() => Edit(Report.id)}
                    />
                    <IoTrashOutline
                      className="text-2xl text-[#F04438] cursor-pointer"
                      onClick={() => Delete(Report.id)}
                    />
                  </div>
                </td>
              </tr>
            ))
          : "Loading"}
      </table>
      <span className="text-sm text-red-600">{Cerror}</span>
      <div
        className={`${
          intrputik ? " hidden" : "flex"
        }  justify-end space-x-8 mt-8 col-start-1 col-end-3`}
      >
        <div
          onClick={Remove}
          className="bg-[#F04438] border-[1px] border-[#D0D5DD] w-fit  flex items-center justify-center  px-5 lg:px-16 py-2 rounded-xl cursor-pointer "
        >
          <p className="text-sm flex items-center justify-center text-white font-Poppins-Regular">
            {"Delete"}
          </p>
        </div>
        <button
          type="button"
          onClick={() => Save()}
          disabled={!Ready}
          className={`${
            Ready ? "bg-[#B7C835]" : "bg-gray-600"
          }  w-fit  flex items-center justify-center px-5 lg-px-28 py-3 rounded-xl cursor-pointer `}
        >
          <p className="text-sm flex items-center justify-center text-white font-Poppins-Regular">
            {"Update"}
          </p>
        </button>
      </div>
    </div>
  );
}

export default ShowCultureReport;
