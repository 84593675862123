import { configureStore } from "@reduxjs/toolkit";
import TupeReducer from "../GlobalData/SystemDashBoard/TupeSlice";
import UnitReducer from "../GlobalData/SystemDashBoard/UnitSlice";
import TestMethodReducer from "../GlobalData/SystemDashBoard/TestMethodSlice";
import GenderReducer from "../GlobalData/SystemDashBoard/GenderSlice";
import DBLabReducer from "../GlobalData/SystemDashBoard/LabSDBSlice";
import AnalysisSBDReducer from "../GlobalData/SystemDashBoard/AnalysisSBDSlice";
import ManeReportSBDReducer from "../GlobalData/SystemDashBoard/ManeReportSDBSlice";
import CultureReportSDBReducer from "../GlobalData/SystemDashBoard/CultureReportSDBSlice";
import IntrputikSDBReducer from "../GlobalData/SystemDashBoard/IntrputikSDBSlice";

import analaytics from "../GlobalData/SystemDashBoard/analaytic";
import histopathologys from "../GlobalData/SystemDashBoard/HistopathologySlice";

import analayticChildReducer from "../GlobalData/SystemDashBoard/analayticChild";
import AnalysisChildSBDSliceReducer from "../GlobalData/SystemDashBoard/AnalysisChildSBDSlice";
import ErrorHandleDBSliceReducer from "../GlobalData/SystemDashBoard/ErrorHandleDBSlice";
import ErrorHandleChildDBSliceReducer from "../GlobalData/SystemDashBoard/ErrorHandleChildDBSlice";
import EditIntrputikReducer from "../GlobalData/SystemDashBoard/EditIntrputikSlice";
import EditManeReportReducer from "../GlobalData/SystemDashBoard/EditManeReportSlice";
import EditCultureReportReducer from "../GlobalData/SystemDashBoard/EditCultureReportSlice";
export const store = configureStore({
  reducer: {
    tupe: TupeReducer,
    unit: UnitReducer,
    test: TestMethodReducer,
    gender: GenderReducer,
    DBLab: DBLabReducer,
    DBanalysis: AnalysisSBDReducer,
    DBManeReport: ManeReportSBDReducer,
    DBCultureReport: CultureReportSDBReducer,
    DBIntrputik: IntrputikSDBReducer,

    analaytic: analaytics,
    histopathology: histopathologys,

    ChildDBanalysis: AnalysisChildSBDSliceReducer,
    errorDB: ErrorHandleDBSliceReducer,
    errorDBChild: ErrorHandleChildDBSliceReducer,

    analayticChild: analayticChildReducer,
    EditIntrputik: EditIntrputikReducer,
    EditManeReport: EditManeReportReducer,
    EditCultureReport: EditCultureReportReducer,
  },
});
