import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getHistopathology } from "../../../../GlobalData/SystemDashBoard/HistopathologySlice";
import swal from "sweetalert";
import { selectDBErrors } from "../../../../GlobalData/SystemDashBoard/ErrorHandleDBSlice";
import { addAllDBAnalysis } from "../../../../GlobalData/SystemDashBoard/AnalysisSBDSlice";
import { getAnalaytic } from "../../../../GlobalData/SystemDashBoard/analaytic";
import { addAllDBManeReport } from "../../../../GlobalData/SystemDashBoard/ManeReportSDBSlice";
import { addAllDBCultureReport } from "../../../../GlobalData/SystemDashBoard/CultureReportSDBSlice";
import Swal from "sweetalert";

function HistopathologySystemDB({
  setCulturReportErrorRedux,
  setGenderError,
setNormalRangeError,
setHighError,
setLowError,
  setHistopathology,
  setDataBeforeFilter,
  histopathology,
  setError,
  type,
  setMError,
  setCError,
  formData,
  intrputik,
  setFormData,
  setErrorName,
  setErrorTupe,
  setErrorTestCode,
  setErrorPriceForPatient,
  setErrorPriceForLap,
  setErrorPriceForCompany,
  setErrorTestMethod,
  setErrorTestUnit,
  ide,
  getData,
  setValue,
  HError,
  setHError,
}) {
  const getAnalaytics = useSelector((state) => state.analaytic.analaytic);
  const getErrors = useSelector(selectDBErrors);

  const dispatch = useDispatch();
  const [Ready, setReady] = useState(true);

  const getSections = async () => {
    await axios.get(`admin-scope/sections`).then((response) => {
      dispatch(addAllDBAnalysis(response.data));
      setDataBeforeFilter(response.data);
    });
  };

  const Save = async () => {
    {
      setReady(false);
      await axios
        .post(`admin-scope/create-section`, {
          once: formData.once,
          test_code: formData.test_code,
          test_print_name: formData.test_print_name,
          price_for_patient: formData.price_for_patient,
          price_for_lap: formData.price_for_lap,
          price_for_company: formData.price_for_company,
          test_method_id: formData.test_method_id,
          test_unit_id: formData.test_unit_id,
          tupe_id: formData.tupe_id,
          antibiotic: 0,
          hsitopology: [{ text: histopathology }],
        })
        .then((res) => {
          setHistopathology("");
          setHError("");
          dispatch(addAllDBManeReport([]));
          dispatch(addAllDBCultureReport([]));
          setErrorName("");
          setMError("");
          setCError("");
          setErrorTestCode("");
          setErrorPriceForPatient("");
          setErrorPriceForLap("");
          setErrorPriceForCompany("");
          setErrorTestMethod("");
          setErrorTestUnit("");
          setErrorTupe("");
          dispatch(getAnalaytic([]));

          setFormData({
            once: 1,
            test_code: "",
            test_print_name: "",
            price_for_patient: "",
            price_for_lap: "",
            price_for_company: "",
            test_method_id: -1,
            test_unit_id: -1,
            class_report: "",
            tupe_id: -1,
          });
          // dispatch(getAnalaytic(form));
          getSections();
          setReady(true);
          setGenderError(false);
setNormalRangeError(false);
setHighError(false);
          setLowError(false);
          setCulturReportErrorRedux(false);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            Swal({
              title: "Opps, Error",
              type: "error",
              icon: "error",
              text: `The system is under maintenance, please try again later, thank you`,
            });
          } else {
            const array = err.response.data.errors;
            if (array["hsitopology.0.text"]) {
              setHError(array["hsitopology.0.text"][0]);
            } else {
              setHError("");
            }

            if (err.response.data.errors.test_print_name) {
              setErrorName(err.response.data.errors.test_print_name[0]);
            } else {
              setErrorName("");
            }
            if (err.response.data.errors.test_code) {
              setErrorTestCode(err.response.data.errors.test_code[0]);
            } else {
              setErrorTestCode("");
            }
            if (err.response.data.errors.price_for_patient) {
              setErrorPriceForPatient(
                err.response.data.errors.price_for_patient[0]
              );
            } else {
              setErrorPriceForPatient("");
            }
            if (err.response.data.errors.price_for_lap) {
              setErrorPriceForLap(err.response.data.errors.price_for_lap[0]);
            } else {
              setErrorPriceForLap("");
            }
            if (err.response.data.errors.price_for_company) {
              setErrorPriceForCompany(
                err.response.data.errors.price_for_company[0]
              );
            } else {
              setErrorPriceForCompany("");
            }
            if (err.response.data.errors.test_method_id) {
              setErrorTestMethod(err.response.data.errors.test_method_id[0]);
            } else {
              setErrorTestMethod("");
            }
            if (err.response.data.errors.test_unit_id) {
              setErrorTestUnit(err.response.data.errors.test_unit_id[0]);
            } else {
              setErrorTestUnit("");
            }
            if (err.response.data.errors.tupe_id) {
              setErrorTupe(err.response.data.errors.tupe_id[0]);
            } else {
              setErrorTupe("");
            }
            // setHError(err.response.data.errors);
            setReady(true);
          }
        });
    }
    formData.append(
      "histopathology[0][text]",
      document.getElementById("histopathology").value
    );
  };
  const handleChangeHistopathology = (e) => {
    setHistopathology(e.target.value);
    dispatch(getHistopathology(e.target.value));
  };
  return (
    <div
      className={`${
        type === "Histopathology" ? "block" : "hidden"
      } col-start-1 col-end-3`}
    >
      <textarea
        id="histopathology"
        required
        placeholder="Type of resalt"
        className="bg-[#F9FAFF] border-[#E4E7EC] w-full h-fit text-xs  flex space-x-2 items-center py-3 px-4 outline-0 ring-0   relative m-auto border-[1px] rounded-xl "
        rows={5}
        onChange={handleChangeHistopathology}
        value={histopathology}
      />
      <span className="text-center  ml-1 text-red-600 text-xs font-Poppins-Regular">
        {HError}
      </span>
      <div
        className={`${
          intrputik ? "hidden" : "flex"
        }  justify-end space-x-8 mt-8 `}
      >
        {/* <div className="bg-[#F04438] border-[1px] border-[#D0D5DD] w-fit  flex items-center justify-center px-5 lg:px-16 py-2 rounded-xl cursor-pointer ">
          <p className="text-sm flex items-center justify-center l text-white font-Poppins-Regular">
            Delete
          </p>
        </div> */}
        <button
          onClick={() => Save()}
          disabled={!Ready}
          className={`${
            Ready ? "bg-[#B7C835]" : "bg-gray-700"
          }  w-fit  flex items-center justify-center px-5 lg:px-28 py-3 rounded-xl `}
        >
          <p className="text-sm flex items-center justify-center text-white font-Poppins-Regular">
            Save
          </p>
        </button>
      </div>
    </div>
  );
}

export default HistopathologySystemDB;
